

















































































































































































































import { SelectApPayment } from "@/components/ApPayment";
import {
  SelectInvoiceAp,
  SelectInvoiceApType,
  SelectInvoiceSuppNo,
} from "@/components/InvoiceAp";
import { debounceProcess } from "@/helpers/debounce";
import {
  Row,
  useApPaymentReport,
  useBlob,
  useBranch,
  useContactData,
  useInvoiceAPPrepayment,
  useLocalFilter,
  useMapContactToOption,
  useMapMasterTypeToOptionAlt,
  useSort,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { Nullable } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { AP_PAYMENT_TYPE_ENUM } from "@/models/enums/ap-payment.enum";
import { ApPaymentListResponseDto } from "@/models/interface/ap-payment";
import { ApPaymentReportDto } from "@/models/interface/ApPaymentReport.interface";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataInvoiceAp } from "@/models/interface/invoiceAp.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

type FormValue = Nullable<{
  branchId: string;
  branchName: string;
  supplierId: string;
  supplierName: string;
  supplierType: string;
  paymentType: AP_PAYMENT_TYPE_ENUM;
  paymentFrom: Moment;
  paymentTo: Moment;
  invoiceApDate: Moment[];
  invoiceApNo: string;
  invoiceSuppNo: string;
  invoiceType: string;
  aPPaymentId: string;
  aPPaymentNo: string;
}>;

type RowTable = Row<ApPaymentReportDto, number>;

@Component({
  components: {
    SelectInvoiceAp,
    SelectInvoiceSuppNo,
    SelectInvoiceApType,
    SelectApPayment,
  },
})
export default class ApPaymentReport extends Mixins(MNotificationVue) {
  @Ref("form") form!: FormModel;

  useLocalFilter = useLocalFilter;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  formModel: FormValue = {
    branchId: null,
    branchName: null,
    paymentFrom: null,
    paymentTo: null,
    paymentType: null,
    supplierId: null,
    supplierName: null,
    supplierType: null,
    invoiceApDate: [],
    invoiceApNo: "",
    invoiceSuppNo: "",
    invoiceType: "",
    aPPaymentId: null,
    aPPaymentNo: null,
  };

  formRules = {
    branchId: [],
    branchName: [],
    paymentFrom: [],
    paymentTo: [],
    paymentType: [],
    supplierId: [],
    supplierName: [],
    supplierType: [],
  };

  loading = {
    supplierType: false,
    branch: false,
    find: false,
    supplier: false,
    download: false,
  };

  optBranch: Option<DataWarehouseBranch>[] = [];
  optSupplier: Option<ContactData>[] = [];
  optSupplierType: Option[] = [];
  optPaymentType: Option[] = [];

  dataSource: RowTable[] = [];
  columns = [
    {
      title: this.$t("lbl_supplier_number"),
      dataIndex: "supplierNumber",
      fixed: "left",
      scopedSlots: { customRender: "nullable" },
      width: "150px",
    },
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "supplierName",
      scopedSlots: { customRender: "nullable" },
      width: "250px",
    },
    {
      title: this.$t("lbl_supplier_type"),
      dataIndex: "supplierType",
      scopedSlots: { customRender: "nullable" },
      width: "200px",
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      scopedSlots: { customRender: "nullable" },
      width: "200px",
      sorter: (a: RowTable, b: RowTable) => {
        return useSort().sortStr(a.invoiceNumber, b.invoiceNumber);
      },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      scopedSlots: { customRender: "date" },
      width: "200px",
    },
    {
      title: this.$t("lbl_invoice_supplier_number"),
      dataIndex: "invoiceSupplierNo",
      scopedSlots: { customRender: "nullable" },
      width: "200px",
    },
    {
      title: this.$t("lbl_tax_invoice_sn"),
      dataIndex: "taxInvoiceNo",
      scopedSlots: { customRender: "nullable" },
      width: "200px",
    },
    {
      title: this.$t("lbl_dpp"),
      dataIndex: "dpp",
      scopedSlots: { customRender: "currency" },
      width: "200px",
    },
    {
      title: this.$t("lbl_ppn"),
      dataIndex: "ppn",
      scopedSlots: { customRender: "currency" },
      width: "200px",
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "total",
      scopedSlots: { customRender: "currency" },
      width: "200px",
    },
    {
      title: this.$t("lbl_ap_payment_amount"),
      dataIndex: "apPaymentAmount",
      scopedSlots: { customRender: "currency" },
      width: "200px",
    },
    {
      title: this.$t("lbl_balance"),
      dataIndex: "balance",
      scopedSlots: { customRender: "currency" },
      width: "200px",
    },
    {
      title: this.$t("lbl_insufficient_payment"),
      dataIndex: "writeOffAmount",
      scopedSlots: { customRender: "currency" },
      width: "200px",
    },
    {
      title: this.$t("lbl_overpayment"),
      dataIndex: "additionalAmount",
      scopedSlots: { customRender: "currency" },
      width: "200px",
    },
    {
      title: this.$t("lbl_ap_payment_no"),
      dataIndex: "payment",
      scopedSlots: { customRender: "nullable" },
      width: "300px",
    },
    {
      title: this.$t("lbl_ap_payment_type"),
      dataIndex: "paymentType",
      scopedSlots: { customRender: "nullable" },
      width: "200px",
    },
    {
      title: this.$t("lbl_invoice_type"),
      dataIndex: "invoiceType",
      scopedSlots: { customRender: "nullable" },
      width: "200px",
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      scopedSlots: { customRender: "nullable" },
      width: "200px",
    },
    {
      title: this.$t("lbl_invoice_ap_status"),
      dataIndex: "invoiceStatus",
      scopedSlots: { customRender: "nullable" },
      width: "200px",
    },
    {
      title: this.$t("lbl_bank_account"),
      dataIndex: "bankAccountName",
      scopedSlots: { customRender: "nullable" },
      width: "200px",
    },
  ];

  pagination = {
    page: FIRST_PAGE,
    limit: 10,
    reset(): void {
      this.page = FIRST_PAGE;
      this.limit = 10;
    },
  };

  created(): void {
    this.onSearchBranch = debounceProcess(this.onSearchBranch);
    this.onSearchSupplier = debounceProcess(this.onSearchSupplier);

    this.fetchBranch(new RequestQueryParams());
    this.fetchSupplier(new RequestQueryParams());
    this.fetchSupplierType();

    const { OPT_PAYMENT_TYPE } = useInvoiceAPPrepayment();
    this.optPaymentType = OPT_PAYMENT_TYPE;
  }

  buildQuery(): string {
    const { filterBy } = useApPaymentReport();
    return filterBy({
      branchId: this.formModel.branchId || "",
      paymentFrom: this.formModel.paymentFrom,
      paymentTo: this.formModel.paymentTo,
      paymentType: this.formModel.paymentType || "",
      supplierId: this.formModel.supplierId || "",
      supplierType: this.formModel.supplierType || "",
      invoiceApDate: this.formModel.invoiceApDate || [],
      invoiceApNo: this.formModel.invoiceApNo || "",
      invoiceSupplierNo: this.formModel.invoiceSuppNo || "",
      invoiceType: this.formModel.invoiceType || "",
      aPPaymentNo: this.formModel.aPPaymentNo || "",
    });
  }

  handleSubmit(): void {
    const params = new RequestQueryParams();
    params.search = this.buildQuery();

    this.findData(params);
  }

  resetForm(): void {
    this.pagination.reset();
    this.form.resetFields();
    this.formModel.branchName = "";
    this.formModel.supplierName = "";
    this.formModel.aPPaymentId = "";
  }

  fetchSupplierType(): void {
    this.loading.supplierType = true;
    const { findAllSupplierType } = useContactData();
    findAllSupplierType()
      .then(res => {
        this.optSupplierType = useMapMasterTypeToOptionAlt(res);
      })
      .finally(() => {
        this.loading.supplierType = false;
      });
  }

  handleDownload(): void {
    const { toDownload } = useBlob();
    const { download, buildParam } = useApPaymentReport();

    const params = new RequestQueryParams();
    params.params = buildParam({
      branchName: this.formModel.branchName || "",
      paymentFrom: this.formModel.paymentFrom,
      paymentTo: this.formModel.paymentTo,
      paymentType: this.formModel.paymentType || "",
      supplierName: this.formModel.supplierName || "",
      supplierType: this.formModel.supplierType || "",
      invoiceApDate: this.formModel.invoiceApDate || [],
      invoiceApNo: this.formModel.invoiceApNo || "",
      invoiceSupplierNo: this.formModel.invoiceSuppNo || "",
      invoiceType: this.formModel.invoiceType || "",
      aPPaymentNo: this.formModel.aPPaymentNo || "",
    });
    params.search = this.buildQuery();

    this.loading.download = true;
    download(params)
      .then(res => {
        toDownload(res, "ap_payment_report.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  /**
   * @description find list ap payment report
   */
  async findData(params: RequestQueryParamsModel): Promise<void> {
    const { findAll, toTableRows } = useApPaymentReport();
    this.pagination.reset();
    try {
      this.loading.find = true;
      const res = await findAll(params);
      this.dataSource = toTableRows(res);
    } catch (error) {
      this.showNotifError("notif_process_fail");
    } finally {
      this.loading.find = false;
    }
  }

  async fetchBranch(params: RequestQueryParams): Promise<void> {
    const { findAll, toOptions } = useBranch();
    try {
      this.loading.branch = true;
      const res = await findAll(params);
      this.optBranch = toOptions(res.data);
    } catch (error) {
      this.showNotifError("notif_process_fail");
    } finally {
      this.loading.branch = false;
    }
  }

  onSearchBranch(search = ""): void {
    const { searchBy } = useBranch();
    const params = new RequestQueryParams();
    params.search = searchBy({ name: search });

    this.fetchBranch(params);
  }

  onChangeBranch(value: string | null): void {
    const opt: Option<DataWarehouseBranch> | undefined = this.optBranch.find(
      e => e.value === value
    );
    this.formModel.branchName = opt?.meta?.name || "";
  }

  fetchSupplier(params: RequestQueryParams): void {
    const { findSuppliers } = useContactData();
    this.loading.supplier = true;
    findSuppliers(params)
      .then(res => {
        this.optSupplier = useMapContactToOption(res);
      })
      .finally(() => {
        this.loading.supplier = false;
      });
  }

  onChangeSupplier(value: string | null): void {
    const opt = this.optSupplier.find(e => e.value === value);
    this.formModel.supplierName = opt?.meta?.firstName || "";
  }

  onSearchSupplier(search = ""): void {
    const { filterBy } = useContactData();
    const params = new RequestQueryParams();
    params.search = filterBy({ firstName: search, lastName: search });

    this.fetchSupplier(params);
  }

  onChangeTable(pagination): void {
    const { current, pageSize } = pagination;
    this.pagination.page = current;

    if (pageSize !== this.pagination.limit) {
      this.pagination.page = FIRST_PAGE;
    }

    this.pagination.limit = pageSize;
  }

  onChangeInvoiceAp(e: Option<DataInvoiceAp> | undefined): void {
    this.formModel.invoiceApNo = e?.meta?.documentNumber || "";
  }

  onChangeInvoiceSupp(e: Option<DataInvoiceAp> | undefined): void {
    this.formModel.invoiceSuppNo = e?.meta?.invoiceSupplierNo || "";
  }

  onChangeApPayment(e: Option<ApPaymentListResponseDto>): void {
    this.formModel.aPPaymentId = e?.meta?.id || "";
    this.formModel.aPPaymentNo = e?.label || "";
  }
}
