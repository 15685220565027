



























import { debounceProcess } from "@/helpers/debounce";
import { useApPayment } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ApPaymentListResponseDto } from "@/models/interface/ap-payment";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectProduct extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<ApPaymentListResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(params = new RequestQueryParams()): void {
    const { findAll, toOptions } = useApPayment();

    this.loading = true;
    findAll(params)
      .then(({ data }) => {
        this.options = toOptions(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useApPayment();
    const params = new RequestQueryParams();

    if (val) {
      params.search = searchBy({ documentNo: val });
    }

    this.fetchOptions(params);
  }

  findOption(value: string): Option<ApPaymentListResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }
}
